import React, { useContext } from "react"
import Layout from "../../components/layout"
import NullIf from "../../services/NullIf"

import ThemeContext from "../../context/ThemeContext"
import { graphql } from "gatsby"
import GenericLandingContentWrapper from "../../components/text-helpers/generic-landing-content-wrapper"
import SEO from "../../components/seo"
import MainSearch from "../../components/main-search/main-search"
import localize from "../../components/localize"
import { localizeItem } from "../../graphql-assistant/createLocaleTextGetter"

const Content = ({ bonuses, locale, filters, ranking }) => {

  let localeRanking = ranking;
  if (localeRanking && localeRanking.casinos){
    localeRanking.casinos.slice().reverse().forEach(casino => {
      bonuses = [...bonuses.filter(item => item.name === casino.name),
        ...bonuses.filter(item => item.name !== casino.name)];
    })
  }


  return (
    <div>
      <MainSearch headerFilters={filters} locale={locale} bonuses={bonuses} />
    </div>
  )
}

const HasNoAccount = ({ location, data, pageContext }) => {
  let site = useContext(ThemeContext)
  let { locale, alternateLocaleSlugs } = pageContext
  let bonuses = site.bonuses
  bonuses = bonuses
    .filter(x => NullIf.isNotNullOrEmpty(x))
    .filter(x => x.hasNoAccount)

  const pageData = data.sanityGenericLandingPage
  const ranking = data.sanityCasinoRanking;

  return (
    <Layout breadcrumbNameOverride={pageData.breadcrumbName} pageContext={pageContext} location={location}>
      <SEO location={location} title={pageData && pageData.seoTitle}
        alternateSlug={alternateLocaleSlugs}
        description={pageData && pageData.seoMeta} bonuses={bonuses}
      />
      <GenericLandingContentWrapper pageData={pageData} headerClassName={`gambleGeneralWrapper`} bodyClassName={`gambleGeneralWrapper`}>
        <Content bonuses={bonuses} ranking={ranking} filters={pageData.headingFilters} locale={locale} />
      </GenericLandingContentWrapper>
    </Layout>
  )
}

export default localize(HasNoAccount)
export const pageQuery = graphql`
  query NoAccountLanding {
    sanityGenericLandingPage(
      name: { eq: "No Account" }
    ) {
      ...GenericLanding
    }
    sanityCasinoRanking (name: {eq: "No Account"}){
        name
        casinos {
            fi {
                name
            }
            en{
                name
            }
        }
    }
  }
`
